<template>
  <!-- <v-container fill-height> -->
  <v-row class="text-center mt-5 pt-5" align="center" justify="center">
    <v-col cols="12">
      <v-img
        :src="require('../assets/cfdm_posterimage_withtext.webp')"
        class="text-center my-3"
        contain
        height="310"
      >
      </v-img>
      <!-- <h1 class="font-weight-regular mb-3" style="color:#B73127">{{$t('online')}}</h1> -->
      <v-row justify="center" class="mt-3">
        <v-btn
          icon
          large
          color="secondary"
          href="https://www.facebook.com/flamencomontreal/"
          target="_blank"
        >
          <v-icon large color="primary">
            facebook
          </v-icon>
        </v-btn>
        <v-btn
          icon
          large
          color="secondary"
          href="https://www.instagram.com/cabaretflamencomtl/"
          target="_blank"
        >
          <v-icon large color="primary">mdi-instagram</v-icon>
        </v-btn>
        <v-btn
          icon
          large
          color="secondary"
          href="https://www.youtube.com/channel/UC93FlBut9bO_SMZYVFAevEQ"
          target="_blank"
        >
          <v-icon large color="primary">mdi-play-circle</v-icon>
        </v-btn>
      </v-row>
    </v-col>

    <v-col class="mb-4">
      <h1 class="font-weight-regular mb-3">
        {{ $t("mission_title") }}
      </h1>
      <p class="subheading font-weight-regular">
        {{ $t("mission_statement") }}
      </p>
      <v-row align="center" justify="center">
        <!-- <v-btn
          rounded
          color="secondary"
          class="text-lowercase text--secondary ma-2"
          @click="show = !show"
        >
          {{ show ? $t("read_less") : $t("read_more") }}
          <v-icon color="primary" right>{{
            show ? "expand_less" : "expand_more"
          }}</v-icon>
        </v-btn> -->
        <!-- <v-btn
          rounded
          color="secondary"
          class="text-lowercase text--secondary ma-2 "
          @click="$vuetify.goTo(target, options)"
        >
          <v-icon left color="primary">favorite</v-icon>
          {{ $t("donate") }}
        </v-btn> -->
      </v-row>
      <v-expand-transition>
        <div class="mt-2" v-show="show">
          <p class="subheading font-weight-regular">
            {{ $t("cabaret_online_p1") }}
          </p>
          <p class="subheading font-weight-regular">
            {{ $t("cabaret_online_p2") }}
          </p>
          <p class="subheading font-weight-regular">
            {{ $t("cabaret_online_p3") }}
          </p>
          <p class="subheading font-weight-regular">
            {{ $t("cabaret_online_p4") }}
          </p>
        </div>
      </v-expand-transition>
    </v-col>

    <v-col cols="12">
      <LandingLiveEvents></LandingLiveEvents>
    </v-col>
    <v-col cols="12">
      <p class="font-weight-bold mb-5">
        {{ $t("next_event") }}
          </p>
          <v-card

        target="_blank"
      >

         <Photos
         source="https://firebasestorage.googleapis.com/v0/b/cabaretflamenco-21095.appspot.com/o/website_photos%2FBANNER_lara_wong_compressed.webp?alt=media&token=a297c1b7-4f92-4da5-9eb7-78ce8bb6f942"
         edges="rounded"
         width="400px"
         height="400px"
         customStyle="text-align: center; margin: auto; margin-top: 20px; margin-bottom: 20px;"
          />
          <p class="subheading font-weight-regular mt-2">
         <a href="https://www.eventbrite.es/e/melon-jimenez-lara-wong-confluencias-tickets-1021804283117?utm-campaign=social&utm-content=attendeeshare&utm-medium=discovery&utm-term=listing&utm-source=cp&aff=ebdsshcopyurl" target="_blank">{{ $t("tickets_for") }} 9th October, 2024</a>
        <v-icon small right>launch</v-icon>
        </p>
      </v-card>
    </v-col>
    <v-col cols="12" class="mt-5">
   <p class="text-capitalize text-center font-weight-bold display-1">{{$t('or')}}</p>
</v-col>
 <v-col cols="12">

      <h2 class="headline font-weight-regular">
        {{ $t("watch_cabaret") }}
      </h2>
      <v-card
        style="cursor:pointer"
        href="https://www.facebook.com/clubespquebec/"
        target="_blank"
      >

         <Photos
         source="https://firebasestorage.googleapis.com/v0/b/cabaretflamenco-21095.appspot.com/o/website_photos%2Fclub_espagnol.webp?alt=media&token=d3e5516e-c70d-43e1-aae0-d4c8c9832b4b"
         edges="rounded"
         width="400px"
         height="400px"
         customStyle="text-align: center; margin: auto; margin-top: 20px; margin-bottom: 20px;"
          />
      </v-card>
      </v-col>
      <v-col class="mb-5" cols="12">
      <h2 class="headline font-weight-regular mb-3">
        {{ $t("donate") }}
      </h2>
      <v-row justify="center">
        <LandingDonation id="#donate"></LandingDonation>
      </v-row>
      <p class="font-weight-regular mt-5">
        {{ $t("donation_statement") }}
      </p>
      <small>
        {{ $t("donation_substatement_1") }}
        <strong>{{ $t("donation_substatement_bold_1") }}</strong>
        {{ $t("donation_substatement_2") }}
        <strong>{{ $t("donation_substatement_bold_2") }}</strong>
        {{ $t("donation_substatement_3") }}
      </small>
    </v-col>
  </v-row>

</template>

<script>
import LandingLiveEvents from '@/components/LandingLiveEvents'
import LandingDonation from '@/components/LandingDonation'
export default {
  name: 'landing-poster-section',
  components: {
    LandingLiveEvents,
    LandingDonation,
    Photos: () => import(/* webpackChunkName: "Photos" */'../utils/Photos')
  },
  created () {
    this.$watch(() => {
      this.$store.dispatch('bindEventsCollection')
    })
  },

  data: () => ({
    show: false
  }),
  computed: {
    target () {
      const value = 9999
      return value
    },
    options () {
      return {
        duration: 2500,
        offset: 0,
        easing: 'easeInOutCubic'
      }
    }
  }
}
</script>
<style scoped>
/* #online {
  position: absolute;
  bottom: -14.5px;
  left: 2.5%;
  right: 2%;
} */
</style>
<i18n>
{
  "en":{
    "online":"on line",
    "or":"or",
    "watch_cabaret":"Come and join our Juerga Flamenco at Club Espagnol de Québec every month. Check out our facebook page.",
    "read_more":"read more...",
    "read_less":"read less",
    "next_event":"Our next featured event",
    "tickets_for":"Tickets for",
    "donate":"Make a donation",
    "mission_title":"Celebrating Flamenco in Montreal",
    "mission_statement":"For more than fifteen years, Cabaret Flamenco de Montréal encourages events and the development of this art in its various forms. It is open to local, international, professional, and emerging artists.",
    "donation_statement":"Would you prefer to send us an interac transfer ? We’re also fine with that ! Here’s the address you can send it to : cabaretflamencomtl@gmail.com",
    "donation_substatement_1":"We entirely depend on your generosity for the shows to happen. Our team is working voluntarily and using personal gear, but ",
    "donation_substatement_bold_1":"studio and venue fees apply, ",
    "donation_substatement_2":"and we try to give our artists",
    "donation_substatement_bold_2":" a decent pay, ",
    "donation_substatement_3":"at a time when they need it more than ever.",
    "cabaret_online_p1": "We stream live shows straight into your living room, using three cameras, studio level audio quality and lights, all of this thanks to our team of passionate volunteers. In order to keep them spontaneous and special, our shows do not stay available online once they are finished.",
    "cabaret_online_p2": "The atmosphere onstage is warm-hearted and informal. It may not be Farruquito dancing on stage, but it’s even better : it’s someone you know personally.",
    "cabaret_online_p3": "As the mission of the Cabaret is also to encourage newcomers, we also give aspiring flamencos the opportunity to be the opening acts for our shows.",
    "cabaret_online_p4": "The Montreal flamenco community is a wonderful thing. It is a meeting place where the demanding discipline of flamenco melts with local cultures, giving birth to a unique flamenco enhanced by the benevolence and witty humor of Quebecers. Help us keep this community alive : pour yourself a glass of wine, tell your aficionado friends to do the same, tune in to our live stream and - if you can - donate to our artists !"

  },
  "fr":{
    "online":"en ligne",
    "or":"ou",
    "watch_cabaret":"Joignez vous à notre Juerga Flamenca au Club Espagnol du Québec chaque mois. Visitez notre page facebook.",
    "read_more":"en savoir plus...",
    "read_less":"en savoir moins",
    "next_event":"Notre prochain événement vedette",
    "tickets_for":"Billets pour",
    "donate":"Faire un don",
    "mission_title":"Célébrer le Flamenco à Montréal",
    "mission_statement": "Depuis plus de quinze ans, le Cabaret Flamenco de Montréal encourage les événements et le développement de cet art sous ses diverses formes. Il est ouvert aux artistes locaux, internationaux, professionnels et émergents.",
    "donation_statement":"Vous préférez nous faire un virement Interac ? Ça nous convient aussi ! Utilisez cette adresse : cabaretflamencomtl@gmail.com",
    "donation_substatement_1":"Nous dépendons entièrement de votre générosité pour faire perdurer le projet. Notre équipe est bénévole et nous utilisons notre matériel personnel, mais des",
    "donation_substatement_bold_1":" frais de studio et lieu",
    "donation_substatement_2":"s’imposent et nous souhaitons verser un",
    "donation_substatement_bold_2":" cachet décent à nos artistes",
    "donation_substatement_3":"qui en ont besoin plus que jamais.",
    "cabaret_online_p1":"Nous diffusons des spectacles en direct grâce un dispositif raffiné (multicam, captation sonore de niveau studio,  éclairages…) et une équipe de volontaires passionnés. Afin de conserver la qualité éphémère et vivante du flamenco, nos spectacles ne sont pas rediffusés.",
    "cabaret_online_p2": "L’ambiance sur scène est chaleureuse et informelle. Ce n’est peut-être pas Farruquito que  vous allez voir danser, mais c’est encore mieux : c’est quelqu’un que vous connaissez  personnellement.",
    "cabaret_online_p3": "Nous donnons  également l’opportunité aux flamencos en apprentissage de monter sur scène pour présenter leur travail en première partie du spectacle principal.",
    "cabaret_online_p4": "La communauté flamenca de Montréal est une merveille. La profonde exigence du flamenco  y fréquente les cultures locales pour donner naissance à un flamenco unique, sublimé par la bienveillance et l’humour des Québécois. Aidez-nous à maintenir cette  communauté en vie : servez-vous un verre de vin, prévenez vos amies aficionados,  connectez-vous à notre diffusion en direct et - si vous le pouvez - donnez à nos artistes !"
  },
  "es":{
    "online":"en línea",
    "or":"o",
    "watch_cabaret":"Únete a nuestra Juerga Flamenca en el Club Espagnol de Québec cada mes. Visite nuestra página facebook.",
    "read_more":"más información...",
    "next_event":"Nuestro próximo evento destacado",
    "tickets_for":"Entradas para",
    "donate":"Hacer una donación",
    "mission_title":"Celebrando el flamenco en Montreal",
    "mission_statement":"Desde hace más de quince años, el Cabaret Flamenco de Montréal fomenta los eventos y el desarrollo de este arte en sus diversas manifestaciones. Está abierto a artistas locales, internacionales, profesionales y emergentes.",
    "donation_statement":"¿Prefieres enviarnos una transferencia Interac? ¡También nos conviene! Usar esta dirección: cabaretflamencomtl@gmail.com",
    "donation_substatement_1":"Dependemos completamente de su generosidad para mantener el proyecto en marcha. Nuestro equipo es benévolo y utilizamos nuestro material personal, pero se aplican ",
    "donation_substatement_bold_1":"tarifas de estudio y local",
    "donation_substatement_2":"y deseamos pagar un",
    "donation_substatement_bold_2":"caché decente a nuestros artistas",
    "donation_substatement_3":"quienes lo necesitan ahora más que nunca.",
    "cabaret_online_p1": "Emitimos shows en vivo gracias a un sofisticado dispositivo (multicámara, grabación de sonido a nivel de estudio, iluminación, etc.) y un equipo de voluntarios apasionados. Para preservar el carácter efímero y la vivacidad del flamenco, nuestros espectáculos no se retransmiten",
    "cabaret_online_p2": "El ambiente en el escenario es cálido e informal. Puede que no veas bailar a Farruquito, pero es aún mejor: es alguien a quien conoces personalmente.",
    "cabaret_online_p3": "También damos la oportunidad a los aprendices flamencos de subir al escenario para presentar su trabajo en la primera parte del espectáculo principal.",
    "cabaret_online_p4": "La comunidad flamenca de Montreal es una maravilla. Las profundas exigencias del flamenco frecuentan allí las culturas locales para dar vida a un flamenco único, sublimado por la benevolencia y el humor quebequenses. Ayúdanos a mantener viva esta comunidad: sírvete una copa de vino, cuéntaselo a tus amigos aficionados, sintoniza nuestra transmisión en vivo y, si puedes, haz una donación a nuestros artistas !"
  }
}
</i18n>
